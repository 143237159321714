.custom-card {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #e0dfdf;
  padding: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; 

  .image-section {
    position: relative;

    .property-image {
      width: 100%;
      height: 90px;
      transition: filter 0.3s ease;
      border-radius: 12px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(3px);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  // Hover Behavior for Non-Selected Cards
  &:not(.border-primary):hover {
    .property-image {
      filter: blur(5px); // Blur the background on hover
    }

    .overlay {
      opacity: 1; // Show overlay on hover
    }
  }

  &.border-primary {
    // No blur or overlay for selected cards
    .property-image {
      filter: none;
    }

    .overlay {
      display: none;
    }
  }
}

.add-button {
  z-index: 2;
  display: block;
  padding: 10px 20px;
  border-radius: 100px;
}



.details-section {
  padding-top: 10px;
  width: 100%;
}

.price-text {
  font-size: 15px;  /* Responsive font for price */
  font-weight: bold;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
  white-space: nowrap;           /* Prevents text from wrapping to a new line */
  overflow: hidden;              /* Ensures any overflowing text is hidden */
  text-overflow: ellipsis;       /* Adds ellipsis (...) when text overflows */
  width: 100%;                   /* Ensures the title takes up full width of its container */
  color: black;
}

// @media (max-width: 768px) {
//   .custom-card {
//     width: 100%;  /* Full width for smaller screens */
//   }

//   .property-image {
//     max-height: 140px;  /* Reduced image height on smaller screens */
//   }

//   .price-text {
//     font-size: 18px;  /* Smaller font for price */
//   }
// }

// @media (max-width: 576px) {
//   .property-image {
//     max-height: 120px;  /* Further reduced height for very small screens */
//   }

//   .price-text {
//     font-size: 16px;  /* Adjust font for small devices */
//   }
// }

.shadow-lg {
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.6) !important;
}