:root {
    --tblr-font-sans-serif: 'Nunito Sans';
    --tblr-primary: #1655FB;
    --tblr-primary-rgb: 22, 85, 251;
  }
  
  @import "@tabler/core/dist/css/tabler.css";
  @import "@fontsource/nunito-sans";
  @import '@fortawesome/fontawesome-free/css/all.min.css';
  @import "./custom.scss";
  @import "./mobile.scss";
  @import "./map.scss";
  @import "./filtering.scss";