.listing-page-container {
    padding: 10px;
    background-color: #fff;
    border-radius: 15px;
    max-width: 100%;
    border: 1px solid #ccc;
    max-height: 550px; /* Ensures the container takes full height */
  display: flex;
  flex-direction: column;
  }
  
  .listing-group-controls, .property-listing, .custom-sort, .custom-detail-page {
    margin-bottom: 5px;
  }
  
  .control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .control-row2 {
    display: flex;
    flex-direction: column;
  }
  
  .drag-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .select-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  .property-listing {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 400px; /* Adjust height based on your layout */
    margin-bottom: 20px;
  }
  
  .toggle-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px; /* Add space between each toggle */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
  }
  
  .switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px; /* Space between the text and the switch */
  }
  
  .switch-text {
    color: #000;
    font-size: 12px;
    margin-right: 2px;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #007bff;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
  @media (max-width: 576px) {
    .switch-label {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    
    .switch-text {
      font-size: 14px; /* Make the text smaller on smaller screens */
    }
  
    .switch {
      width: 34px;
      height: 20px;
    }
  }
  
  .no-listing-message {
    text-align: center;
    font-size: 18px;
    color: #666;
    padding: 20px;
  }
  
  .property-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .property-thumb {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .property-info {
    flex-grow: 1;
    margin: 2px 0;
  }
  
  .property-actions i {
    font-size: 24px;
    cursor: pointer;
  }
  
  .custom-sort, .custom-detail-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .input-text {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 10px;
  }
  