/* Base Styles */
.fixed-search-section-mls {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .map-preview-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  
  .map-preview-content-mls {
    display: flex;
    height: 100%;
    margin-top: 200px;
  }
  
  .map-container-mls  {
    flex: 1;
    height: calc(100vh - 200px);
  }
  
  .listing-cards-mls  {
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 200px);
    margin: 20px;
    padding-bottom: 20px;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    /* Adjust search section */
    .fixed-search-section {
      position: static;
      padding: 10px;
      box-shadow: none;
      
    }
  
    /* Stack content vertically */
    .map-preview-container {
      flex-direction: column;
      overflow: auto;
    }
  
    .map-preview-content {
      flex-direction: column;
      height: 100vh;
      margin-top: 0;
    }
  
    /* Map should take up half of the viewport height */
    .map-container {
      height: 50vh;
      width: 100%;
    }
  
    /* Listing cards take up the remaining space */
    .listing-cards {
      height: 50vh;
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
    }
  }
  
  /* Tablet Adjustments */
  @media (min-width: 768px) and (max-width: 1024px) {
    .map-container,
    .listing-cards {
      height: 40vh;
    }
  
    .map-preview-content {
      flex-direction: column;
      height: 100vh;
    }
  }
  
  /* Leaflet Zoom Button Styles */
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    border: none;
  }
  
  .leaflet-control-zoom-in:hover,
  .leaflet-control-zoom-out:hover {
    background-color: #e0e0e0;
    color: black;
  }
  