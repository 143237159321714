.container {
    padding: 20px;
  }
  
  .tab-links button {
    margin: 0 10px;
    color: #007bff;
    border: none;
    background: none;
    font-weight: 500;
  }
  
  .tab-links button.active {
    font-weight: bold;
    border-bottom: 2px solid #007bff;
  }
  
  .main-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .filters {
    font-size: 16px;
  }
  
  .tab-content {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .footer-buttons .btn {
    padding: 10px 20px;
  }
  