.property-detail-section {
    width: 65%;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .property-image-large {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .property-detail-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    gap: 8px;
  }
  
  .property-title {
    font-size: 10px;
    margin-top: 10px;
  }
  
  .property-description {
    font-size: 16px;
    color: #777;
  }
  
  .action-icons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .action-icons .tabler-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .action-icons .tabler-icon:hover {
    color: #007bff;
  }

  .no-listing-message {
    text-align: center;
    font-size: 18px;
    color: #666;
    padding: 20px;
  }