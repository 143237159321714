.filter-section {
  max-width: 100%;
}

.filter-section h4 {
  font-size: 16px;
}

.form-range {
  background-color: #e9ecef;
}

/* Filter Section Styles */
.filter-section-container {
  position: relative;
  background-color: #fff;
  padding: 15px;
  z-index: 1000;
}

/* Hidden by default on small screens */
.filter-section-container.hidden {
  display: none;
}

/* Floating Filter Button */
.floating-filter-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1655FB;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1050;
}

/* Close Button Inside Filter Section */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
  color: #333;
  z-index: 1050;
}

/* Media Queries */
@media (max-width: 770px) {
  .filter-section-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 1050;
  }

  .filter-section-container.hidden {
    display: none;
  }
}