.btn-transparent {
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white background */
  border: none;
  /* Remove border */
}

/* In your global CSS file (e.g., index.css or App.css) */
body {
  background-color: white;
}

.btn-transparent:hover {
  background-color: rgba(255, 255, 255, 0.9);
  /* Slightly less transparent on hover */
}
#blur-background {
  transition: filter 0.3s ease-in-out;
}

#blur-background.blurred {
  filter: blur(5px); /* Adjust the blur strength */
}

.card-title-analytics{
  font-size: xx-large;
}

.bg-tabs {
  background-color: #D0DDFE;
}

// form  radius
.round-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.round-left {
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}

.round-right {
  border-top-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}

.round-bottom-left {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}

.round-bottom-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}

// back button
.back-button {
  position: absolute;
  top: 0;
  start: 0;
}

@media (min-width: 992px) {
  .back-button {
    position: absolute;
    top: 0;
    start: 50;
  }
}

.modal-open {
  overflow: hidden;
}


/* Modal */
body.modal-open .content-blur {
  
  


}

body.modal-open .content-blur::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  /* Change the color and transparency here */
  backdrop-filter: blur(2px);
  pointer-events: none;
  overflow-y: hidden;
 
}

.modal-content {
  border-radius: 15px;
  padding: 20px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-body {
  overflow: scroll;
  padding: 20px 10px;
  max-height: 460px;
}

.modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn-skip {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  border-radius: 50px;
  padding: 10px 20px;
}

.btn-next {
  background-color: #1655FB;
  border: none;
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
}

.btn-next:disabled {
  background-color: #2c67fa;
  color: #bab9b9;
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-next:disabled .d-flex {
  color: #e4dfdf; /* Ensures the div inside also appears disabled */
}

.btn-trash {
  background-color: #ff5e5e;
  border: none;
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
}

select,
input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

select {
  appearance: none;
}

.modal-footer button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}



// sidebar
.sidebar-fixed {

  width: 100%; /* Ensure full width */
  max-width: 250px; /* Fixed width after medium screen */
  // overflow: auto;6e070ce0b18b4595b4e1544436221fe8236b50e2
}


.main-content {
  margin-left: 0;
  /* Adjust as needed to match sidebar width */
}

.mobile-side-bar {
  width: 250px; 
  max-width: 250px; /* Adjust as needed to match sidebar width */
  position: fixed;
  overflow: hidden;
  // overflow: auto;
}

.mobile-main-content {
  overflow: hidden;
  position: relative;
  top: 0;
  start: 0;
  margin-left: 250px; /* Adjust as needed to match sidebar width */
}


.mobile-main-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Change the color and transparency here */
  backdrop-filter: blur(2px);
  pointer-events: none;/* Apply the blur effect */
}



.hover-tabs:hover {
  background-color: rgb(227, 231, 243);
}

@media (min-width: 768px) {
  .sidebar-fixed {
    position: fixed;
  }

  .main-content {
    margin-left: 250px;
  }
}

/* Ensure the sidebar is independently scrollable */
.listing-sidebar-fixed {
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  position: sticky; /* Sticky positioning to keep the sidebar in view */
  top: 0; /* Stick to the top of the viewport *//* Light background to distinguish from content */
  padding: 20px; /* Add some padding for aesthetics */
 /* Add a border to separate the sidebar visually */
}

.listing-main-content { /* Adjust padding as needed */
  overflow: auto; /* Enable scrolling for the main content if necessary */
}

/* Customize scrollbars for a better look */
.listing-sidebar-fixed::-webkit-scrollbar {
  width: 8px;
}

.listing-sidebar-fixed::-webkit-scrollbar-thumb {
  background-color: #1655FB; /* Color for the scrollbar thumb */
  border-radius: 4px; /* Round the edges of the scrollbar thumb */
}

.listing-main-content::-webkit-scrollbar {
  width: 8px;
}

.listing-main-content::-webkit-scrollbar-thumb {
  background-color: #1655FB; /* Color for the scrollbar thumb */
  border-radius: 4px; /* Round the edges of the scrollbar thumb */
}



carousel .carousel {
  width: 100%;
  /* Adjust the width as needed */
  max-width: 50px;
  /* Adjust the maximum width as needed */
  margin: auto;
  /* Center the carousel */
}



.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.carousel-button:focus {
  outline: none;
}

.carousel-indicators button {
  background-color: #ddd;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-indicators button.active {
  background-color: #333;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  /* Ensure the images scale correctly */

.PhoneInput {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #dadfe5;
  overflow: hidden; /* Ensure no gaps between elements */
  transition: box-shadow 0.15s ease-in-out;
}

.PhoneInput:focus-within {
  box-shadow: 0 0 0 4px #bfe1f5
}

.PhoneInputCountry {
  border: none;
  border-radius: 25px 0 0 25px; /* Only round the left corners */
  display: flex;
  align-items: center;
  height: 38px; /* Set the height to match the input field */
  padding: 0 10px;
  background-color: #fff;
}

.PhoneInputCountry select {
  height: 100%;
  border: none;
  background: none;
  padding: 0 10px; /* Adjust padding as necessary */
  outline: none; /* Remove outline */
  margin: 0; /* Remove margin */
}

.PhoneInputInput {
  flex: 1; /* Allow the input to take up remaining space */
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: none;
  border-radius: 0 25px 25px 0; /* Only round the right corners */
  height: 38px; /* Set the height to match the flag icon area */
  padding: 0 5px; /* Adjust padding as necessary */
  margin: 0; /* Remove margin */
}

.PhoneInputInput:focus {
  color: #495057;
  background-color: #fff;
  border: 1px solid #9db6e7;
  outline: none;
}

.phone-input .PhoneInputCountrySelect[aria-label] {
  color: black; /* This will make the text transparent */
}

/* Hide the country name only when read-only */
.read-only .PhoneInputCountrySelect[aria-label] {
  color: transparent; /* Make the text transparent */
}

/* Ensure the dropdown is still functional */
.read-only .PhoneInputCountry {
  pointer-events: none; /* Prevent interaction with the dropdown */
}


.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}


/* src/components/Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:white;
  z-index: 9999;
}

// .loader {
//   border: 16px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 16px solid #3498db;
//   width: 120px;
//   height: 120px;
//   animation: spin 2s linear infinite;
// }

// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }


// .nav-link {
//   color: #333;
//   padding: 10px;
//   cursor: pointer;
// }

.nav-link.active {
  color: #1655FB;
  font-weight: bold;
}


/* Custom modal dialog to control the width and height of the modal */
.side-bar-listing { /* Adjust as needed to match sidebar width */
  position: fixed;
  overflow: auto;
}

.modal-body-listing {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
  max-height: 450px;
}

@media (min-width: 1200px) {
  .modal-body-listing {
    max-height: 720px;
  }
}
.modal-body-listing-form {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
  max-height: 400px;
}

@media (min-width: 1200px) {
  .modal-body-listing-form {
    max-height: 560px;
  }
}
.custom-modal-dialog {
  max-width: 95%; /* Adjust the width as needed */
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.custom-modal-content {
  height: 100vh; /* Adjust the height as needed */
   /* Ensure scrolling for long content */
}

/* Ensure the sidebar and content sections have a set height and overflow-y if necessary */
.custom-modal-content .row {
  height: 100%;
}

.custom-modal-content .col-3 {
  height: 100%;
  
}

.custom-modal-content .col-9 {
  height: 100%;
 
}

.sidebar-with-line {
  position: relative;
  padding-left: 20px;
}

.sidebar-with-line::before {
  content: '';
  position: absolute;
  left: 10px; /* Adjust this value to position the line correctly */
  top: 0;
  bottom: 0;
  width: 2px; /* Adjust the width of the line */
  background-color: #d3d3d3; /* Adjust the color of the line */
}
.nav-link {
  padding: 0;
  margin: 0;
}

.timeline {
  position: relative;
  list-style: none;
}

.timeline-event {
  display: flex;
  align-items: center;
  position: relative;
}

.timeline-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.timeline-icon {
  z-index: 1;
}

.timeline-line {
  position: absolute;
  top: 20px; /* Adjust to align with the icon */
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: #b1d6f5; /* Adjust the color as needed */
  transform: translateX(-50%);
}

.custom-text {
  color: #b1d6f5;
}

.nav-link a {
  display: block;
  width: 100%;
  padding: 0px 0; /* Adjust as needed for link padding */
  text-decoration: none;
}

.nav-link a.active {
  font-weight: bold;
}

.nav-link a.text-muted {
  color: #93c6f1;
}


.section-bg{
  background: rgba(255, 255, 255, 0.8)
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 10px;
  margin: 10px;
}

.tab {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.tab.active {
  background-color: #1655FB;
  color: #fff;
}

// .nav-link.active::after {
//   content: '●';
//   position: absolute;
//   left: -20px; /* Adjust this value to position the active dot correctly */
//   top: 50%;
//   transform: translateY(-50%);
//   color: #0000ff; /* Adjust the color of the active dot */
//   font-size: 14px; /* Adjust the size of the active dot */
//   font-weight: bold;
// }

.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  gap: 10px; /* Uniform spacing between cards * /* Center the container */
  padding: 10px; /* Add padding for better spacing */
}

.card-listing-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Responsive columns */
  gap: 15px; /* Space between cards */
  justify-items: center; /* Center align cards if fewer items in a row */
}

.card-listing-mls {
  flex: 1 1 calc(25% - 20px);
  max-width: 260px; /* Prevent cards from growing too large */
  min-width: 260px; /* Ensure a minimum width */
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column; /* Align content vertically */
}

.card-listing {
  flex: 1 1 calc(25% - 15px); /* 4 cards per row, considering the gap */
  max-width: 250px; /* Prevent cards from growing too large */
  min-width: 245px; /* Ensure a minimum width for better layout */
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column; /* Align content vertically */
  // margin: 10px;
}

.card-listing:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-listing img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.card-listing-mls img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-listing-details {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
}

/* Responsive adjustments for medium screens */
@media (max-width: 768px) {
  .card-listing {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row */
  }
}

/* Responsive adjustments for small screens */
@media (max-width: 480px) {
  .card-listing {
    flex: 1 1 100%; /* Full width */
    max-width: none; /* No maximum width constraint */
    min-width: 100%;
  }

  .card-listing-details {
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

textarea.flex-grow-1 {
  flex-grow: 1;
  min-height: 213px; /* Optional: set a minimum height */
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.price-input {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.price-input label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.price-input .d-flex {
  display: flex;
  align-items: center;
}

.price-input .currency-display {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.price-input .currency-display span {
  margin-left: 0.5rem;
}

.price-input .country-select {
  margin-left: 0.5rem;
  width: 200px;
}

.price-input .form-control {
  flex: 1;
}


.tab-navigation {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-bottom: 20px;
}

.tab-item {
  padding: 10px 10px;
  cursor: pointer;
  margin: 5px;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}

.tab-item:hover {
  background-color: #f0f0f0;
}

.tab-item.active {
  border-bottom: 1px solid #000;
  background-color: #000;
  color: #fff;
  font-weight: bold;
}

.tab-content {
  padding: 15px;
  background-color: #fff;
}

// @media (max-width: 480px) {
//   .tab-navigation {
//     flex-direction: column; /* Stack tabs vertically on small screens */
//     align-items: stretch; /* Ensure tabs span full width */
//   }

//   .tab-item {
//     flex: none; /* Reset flex behavior for vertical layout */
//     width: 100%; /* Make tabs full width */
//     max-width: none; /* Remove max-width constraint */
//   }
// }

.btn-black {
  background-color: #000;
  border-color: #000;
  border-radius: 15px;
  color: #fff;
}

.btn-black-outline {
  background-color: #fff;
  border-color: #000;
  border-radius: 15px;
  color: #000;
}

.table{
  border-radius: 15px;
}

// .property-details-container {
//   display: flex;
//   gap: 1.5rem;
//   margin: 0 auto;
//   padding: 0 1rem;
// }

// .property-images {
//   flex: 1;
// }

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  max-height: 350px;
}

.image-item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  // max-height: 240px;
}

.main-image {
  grid-column: span 2;
  grid-row: span 2;
  // height: 500px;
}

.thumbnail {
  grid-column: span 1;
  grid-row: span 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .main-image {
    grid-column: span 2; /* Full width */
    height: 200px;
  }

  .thumbnail {
    grid-column: span 1; /* Keep thumbnails in two columns below */
  }
}

.property-info {
  flex: 0.4;
}

.property-title {
  font-size: 2rem;
  font-weight: bold;
}

.property-type, .property-date, .property-price {
  margin: 0.5rem 0;
}

.property-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.map-view {
  margin-top: 1.5rem;
}

.map-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.schedule-viewing, .calculate-mortgage {
  margin-top: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}


.currency-input-container {
  display: flex;
  align-items: center;
}

.amount-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  flex: 1;
}

.country-select {
  border-left: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}



.currency-dropdown {
  min-width: 120px;
  border-top-right-radius: 10px;
}

.custom-single-value {
  display: flex;
  align-items: center;
}

.custom-single-value .currency-label {
  margin-left: 20px;
}

.custom-option {
  display: flex;
  align-items: center;
  padding: 8px;
}

.custom-option .currency-label {
  margin-left: 8px;
  font-weight: bold;
}

.custom-option .currency-name {
  margin-left: 8px;
  color: #888;
}

.currency-input {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  overflow: hidden;
  padding: 0.5rem 0.5rem;
  background-color: #f7f9fc;
  width: fit-content;
}

.currency-field {
  border: none;
  outline: none;
  padding: 0.5rem;
  flex-grow: 1;
  background-color: transparent;
  font-size: 1rem;
}

.currency-code {
  background-color: #f0f4f8;
  padding: 0.5rem 1rem;
  border-left: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.country-select {
  border-left: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}

.country-select__control {
  border: none !important;
  background-color: transparent;
  box-shadow: none;
}

.country-select__indicator-separator {
  display: none;
}

.country-select__single-value {
  display: flex;
  align-items: center;
}

.country-select__single-value img {
  margin-right: 5px;
}


.custom-flags-select {
  border-radius: 0px 20px 20px 0px; /* Apply border-radius only to the right side */
}

/* Modal Background */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1060;
}

/* Modal Content */
.custom-modal-content {
  background-color: #fff;
  width: 600px;
  height: fit-content;
  border-radius: 15px;
  padding: 10px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal Header */
.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-modal-title {
  margin: 0;
}

/* Close Button */
// .btn-close {
//   background: none;
//   border: none;
//   cursor: pointer;
// }

/* Modal Body */
.custom-modal-body .input-group {
  display: flex;
  align-items: center;
}

.custom-modal-body .input-group .form-control {
  flex: 1;
}

/* Modal Footer */
.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}


.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 15px;
}

.pagination-number {
  background-color: transparent;
  border: transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0086d7;
}

.pagination-number.active {
  color: #ffffff; /* Active page color */
  background-color: #1655FB;
  border: 1px solid #1655FB;
}

.pagination-number:hover {
  // background-color: #e0e0e0;
  // color: #ffffff;
  // border: 1px solid #000000;
}

.pagination-arrow {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 41px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;
}

.pagination-arrow:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

span {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-content-body {
  border-radius: 15px;
}


.video-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (height / width = 9 / 16 * 100) */
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.terminated {
  background-color: #8B0000;
}
.expired {
  background-color: #2F4F4F;
}

.cancelled{
  background-color: #DC143C;
}

.wait-approval{
  background-color: #00008BCC;
}

.pending{
  background-color: #FF8C00CC;
}

.deleted{
  background-color: #B22222CC;
}

.unknown{
  background-color: #696969CC;
}


.marketing-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Optional overlay */
  backdrop-filter: blur(5px); /* Apply blur effect */
  z-index: 1;
}

.centered-message {
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .table td,
  .table th {
    white-space: nowrap; /* Prevent text from wrapping */
  }
}

.read-only-phone-input .react-phone-input__country {
  display: none; /* or visibility: hidden; */
}


.news-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  padding: 15px;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.news-card:hover {
  transform: translateY(-5px);
}

.news-card-image img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.news-card-content {
  padding: 10px 0;
}

.news-card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.news-card-description {
  font-size: 0.9rem;
  color: #777;
  line-height: 1.4;
  margin-top: 5px;
  max-height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}


.date-picker-container {
  position: relative;
}

.date-picker-toggle {
  display: inline-flex;
  align-items: center;
  border: 1px solid #0057ff;
  padding: 8px 15px;
  border-radius: 30px;
  cursor: pointer;
  background-color: white;
}


/* Dropdown styles */
.dropdown-menu {
  display: none;
  // position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 200px; /* Set a fixed width */
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

/* Responsive styles */
@media (max-width: 768px) {
  .dropdown-menu {
    width: 100%; /* Make it full width on mobile */
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .dropdown-item {
    padding: 15px; /* Increase padding for better touch usability */
  }

  .btn-pill {
    width: 100%; /* Make the button full width */
  }
}


/* Completely hide any default SVGs within the zoom controls */
.leaflet-control-zoom-in svg,
.leaflet-control-zoom-out svg {
  display: none !important;
}

/* Hide any spans or other extraneous elements */
.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span,
.leaflet-control-zoom-in div,
.leaflet-control-zoom-out div {
  display: none !important;
}

/* Style the zoom controls with custom colors and sizes */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: white;  /* Button background color */
  color: black;             /* Button text/icon color */
  font-weight: bold;
  font-size: 18px;          /* Font size for custom icon */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

/* Add custom + and - symbols */
.leaflet-control-zoom-in::before {
  content: "+";  /* Custom zoom-in symbol */
}

.leaflet-control-zoom-out::before {
  content: "-";  /* Custom zoom-out symbol */
}

/* Optional: Hover effect for visual feedback */
.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #e0e0e0;  /* Background color on hover */
}

.filter-section-container {
  /* Set a fixed height for the filter section */
  height: calc(100vh - 180px);  /* Adjust as needed */
  min-width: 200px;

  /* Enable vertical scrolling if the content overflows */
  overflow-y: auto;

  /* Optional: Add padding and a border to make it more visually appealing */
  padding: 10px;
}

.status-options-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 1px; /* Adjust the gap between columns */
}


.pagination-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination-btn {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.pagination-btn.disabled {
  color: #cccccc;
  cursor: not-allowed;
}

.pagination-circle {
  width: 40px;
  height: 40px;
  border: 2px solid #007bff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
  background-color: white;
}

.pagination-circle.active {
  background-color: #007bff;
  color: white;
}




.info-item {
  // text-align: center; /* Align content in the center */
  // flex: 1; /* Make items take equal space */
  // min-width: 100px; /* Ensure items wrap nicely on smaller screens */
  // padding: 0 5px; /* Adjust horizontal padding */
}


@media (max-width: 767.98px) {
  .border-end {
    border-right: none !important; /* Disable border on small screens */
  }
}

// @media (max-width: 768px) {
//   .info-item {
//     flex: 1 1 50%; /* Show 2 items per row on smaller screens */
//     text-align: left; /* Align text to the left for smaller viewports */
//   }
// }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1040; /* Below the modal, above the rest */
}

.table-fixed {
  width: 100%;
  overflow-x: auto;
}

.table-fixed td,
.table-fixed th {
  width: 50%;
  word-wrap: break-word;
  text-align: left;
  padding: 8px;
}